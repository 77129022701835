/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-ribbon-wrapper {
  position: relative;
}
.ant-ribbon {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: 8px;
  height: 22px;
  padding: 0 8px;
  color: #fff;
  line-height: 22px;
  white-space: nowrap;
  background-color: #1890ff;
  border-radius: 2px;
}
.ant-ribbon-corner {
  position: absolute;
  top: 100%;
  width: 8px;
  height: 8px;
  color: #1890ff;
  border: 4px solid;
  transform: scaleY(0.75);
  transform-origin: top;
}
.ant-ribbon-corner::after {
  position: absolute;
  top: -4px;
  left: -4px;
  width: inherit;
  height: inherit;
  color: rgba(0, 0, 0, 0.25);
  border: inherit;
  content: '';
}
.ant-ribbon-color-pink {
  background-color: #eb2f96;
}
.ant-ribbon-color-pink .ant-ribbon-corner {
  color: #eb2f96;
}
.ant-ribbon-color-magenta {
  background-color: #eb2f96;
}
.ant-ribbon-color-magenta .ant-ribbon-corner {
  color: #eb2f96;
}
.ant-ribbon-color-red {
  background-color: #f5222d;
}
.ant-ribbon-color-red .ant-ribbon-corner {
  color: #f5222d;
}
.ant-ribbon-color-volcano {
  background-color: #fa541c;
}
.ant-ribbon-color-volcano .ant-ribbon-corner {
  color: #fa541c;
}
.ant-ribbon-color-orange {
  background-color: #fa8c16;
}
.ant-ribbon-color-orange .ant-ribbon-corner {
  color: #fa8c16;
}
.ant-ribbon-color-yellow {
  background-color: #fadb14;
}
.ant-ribbon-color-yellow .ant-ribbon-corner {
  color: #fadb14;
}
.ant-ribbon-color-gold {
  background-color: #faad14;
}
.ant-ribbon-color-gold .ant-ribbon-corner {
  color: #faad14;
}
.ant-ribbon-color-cyan {
  background-color: #13c2c2;
}
.ant-ribbon-color-cyan .ant-ribbon-corner {
  color: #13c2c2;
}
.ant-ribbon-color-lime {
  background-color: #a0d911;
}
.ant-ribbon-color-lime .ant-ribbon-corner {
  color: #a0d911;
}
.ant-ribbon-color-green {
  background-color: #52c41a;
}
.ant-ribbon-color-green .ant-ribbon-corner {
  color: #52c41a;
}
.ant-ribbon-color-blue {
  background-color: #1890ff;
}
.ant-ribbon-color-blue .ant-ribbon-corner {
  color: #1890ff;
}
.ant-ribbon-color-geekblue {
  background-color: #2f54eb;
}
.ant-ribbon-color-geekblue .ant-ribbon-corner {
  color: #2f54eb;
}
.ant-ribbon-color-purple {
  background-color: #722ed1;
}
.ant-ribbon-color-purple .ant-ribbon-corner {
  color: #722ed1;
}
.ant-ribbon.ant-ribbon-placement-end {
  right: -8px;
  border-bottom-right-radius: 0;
}
.ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner {
  right: 0;
  border-color: currentColor transparent transparent currentColor;
}
.ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner::after {
  border-color: currentColor transparent transparent currentColor;
}
.ant-ribbon.ant-ribbon-placement-start {
  left: -8px;
  border-bottom-left-radius: 0;
}
.ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner {
  left: 0;
  border-color: currentColor currentColor transparent transparent;
}
.ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner::after {
  border-color: currentColor currentColor transparent transparent;
}
